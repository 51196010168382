import Vue from 'vue'
import './registerServiceWorker'
import LoadShell from './helpers/loadShell'

Vue.use(LoadShell)

Vue.loadShell()
  .then(() => {
    import('./services/entry')
      .then((entry) => {
        entry.default.start()
      })
      .catch(err => alert(err))
  })
  .catch(err => alert(err))