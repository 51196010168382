const LoadShell = {
  install: function (Vue) {
    Vue.loadShell = Vue.prototype.$loadShell = function () { // eslint-disable-line no-param-reassign
      return new Promise(function (resolve, reject) {
        loadScript('/shell/shell.js')
          .then(() => {
            window.sknk.initialize()
              .then(() => resolve())
              .catch(err => reject(err))
          })
          .catch(err => reject(err))
      })
    }

    Vue.unloadShell = Vue.prototype.$unloadShell = function (src) { // eslint-disable-line no-param-reassign
      return new Promise(function (resolve, reject) {
        const el = document.querySelector('script[src="' + src + '"]')

        if (!el) {
          reject()

          return
        }

        document.head.removeChild(el)

        resolve()
      })
    }
  },
}

function loadScript(src){
  return new Promise(function (resolve, reject) {

    if (document.querySelector('script[src="' + src + '"]')) {
      resolve()

      return
    }

    const el = document.createElement('script')

    el.type = 'module'
    el.async = true
    el.src = src

    el.addEventListener('load', resolve)
    el.addEventListener('error', reject)
    el.addEventListener('abort', reject)

    document.head.appendChild(el)
  })
}

export default LoadShell